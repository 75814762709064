import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import api from '../../../src/api/api';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import Modal from '../modal/Modal';
import Cookies from 'js-cookie';


const id = Cookies.get('id');
const Login = () => {
    const navigate = useNavigate();


    return (
        <div className="login-container" style={{ marginTop: "140px" }}>
      <h1>Please Login!</h1>
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          api.post(`/auth/signIn`,
            {
            email: values.email,
            password: values.password,
          })


            .then((response) => {
              
              console.log("response.dataaaa",response);
  
              notification.success({ message: 'Sign-in successful!' });
              Cookies.set('id', response.data.data.id, { secure: true, sameSite: 'Strict' });
              navigate("/my-account");
              //  window.location.href = "/my-account";
               setSubmitting(false)
            })
            .catch((error) => {
             
                notification.error({ message: error.message });
             
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="login-form" onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Email"
            />
            {errors.email && touched.email && <div className="error-message">{errors.email}</div>}
            <input
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              placeholder="Password"
            />
            {errors.password && touched.password && <div className="error-message">{errors.password}</div>}
            <button type="submit" disabled={isSubmitting}>
              Login
            </button>
          </form>
        )}
      </Formik>
      
    </div>
    );
};

export default Login;