import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const AffiliatePageSection4 = () => {
    return (
        <div className='affiliatesection4-main'>
            <div className='container'>

                <div className='affiliatesection4-head'>
                    <h1>Earn big with our affiliate program</h1>
                    <p>As an affiliate you can earn commission for every pre-order you secure. Here's how our commission structure works</p>
                </div>
                <div className="row rowgapsection4">
                    <div className='col-md-4'>
                        <div className="affiliatesection4-card1">
                            <div className='affiliatesection4-card1-btn'>
                                Commission Range
                            </div>
                            <p>100 Per confirm order.</p>
                        </div>
                    </div>

                    <div className=' col-md-4'>
                        <div className="affiliatesection4-card2">
                            <div className='affiliatesection4-card1-btn-2'>
                                High Earning Potential
                            </div>
                            <p>The more pre-orders you generate, the more you earn.</p>
                        </div>
                    </div>

                    <div className=' col-md-4'>
                        <div className="affiliatesection4-card3">
                            <div className='affiliatesection4-card1-btn'>
                                No cap on Earnings
                            </div>
                            <p>There's no limit to how much you can earn.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AffiliatePageSection4;