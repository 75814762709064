// src/components/RedirectWithDelay.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectWithDelay = ({ message, delay = 5000, to = "/" }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate(to);
        }, delay);

        return () => clearTimeout(timer);
    }, [navigate, delay, to]);

    return <div className="redirect-message" style={{marginTop:"160px"}}>
          {message}
</div>;
};
export default RedirectWithDelay;