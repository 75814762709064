import React from 'react';
import { useAuth } from './AuthContext';
import RedirectWithDelay from '../Auth/RedirectWithDelay';

function ProtectedRoute({ children }) {
    const { isAuthenticated } = useAuth();


    if (isAuthenticated) {
        return children;
    }

    return (
        <RedirectWithDelay message="Illegal operation. Redirecting to the homepage..." />
    );
}

export default ProtectedRoute;