import React from "react";

const PaymentSuccessModal = () => {
    return (
        <div className="paymentsuccessfull">
      <div className="modal-content">
        <p>Thank you! Your payment was successful.</p>
      </div>
    </div>
    );
};
export default PaymentSuccessModal;