// src/App.js

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import "../src/assets/scss/main.css";
import HomePage from "./pages/HomePage";
import SupportPage from "./pages/SupportPage";
import AboutUs from "./pages/AboutUs";
import Modal from "./components/modal/Modal";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import PaymentSuccessModal from "./components/modal/productdetails/PaymentSuccessModel";
import Login from "./components/Auth/Login";
import { AuthProvider } from './components/Auth/AuthContext';
import ProtectedRoute from './components/Auth/ProtectedRouter'; 
import PaymentFailed from "./components/modal/productdetails/PaymentFailed";
import MyAccount from "./components/MyAccount/MyAccount";
import AffiliatePage from "./components/AffiliatePage/AffiliatePage";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          
          {/* <Route
            path="/thank-you"
            element={<ProtectedRoute element={<PaymentSuccessModal />} />}
          /> */}
  
         
          <Route
            path="/payment-failed"
            element={<ProtectedRoute element={<PaymentFailed />} />}
          />
           {/* <Route
            path="/my-account"
            element={<ProtectedRoute element={<MyAccount/>} />}
          /> */}
          <Route path="/login" element={<Login />} />

           <Route path="/affiliate" element={<AffiliatePage />} />

            <Route path="/thank-you" element={<PaymentSuccessModal />} /> 
            <Route path="/my-account" element={<MyAccount />} /> 
          
        </Routes>
        <Modal />
        <Footer />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
