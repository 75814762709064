import React from "react";

const PaymentFailed = () => {
    return (
        <div className="paymentsuccessfull"> 
    <p>Thank you! Your payment was failed.</p>
    </div>
    );
};

export default PaymentFailed;