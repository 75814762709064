import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { GoArrowDown } from "react-icons/go";

const ProgressBar = ({ value }) => {
    return (
        <div style={{ position: 'relative', width: '60px', height: '60px' }}>
      <CircularProgressbar
         value={value}
          styles={buildStyles({
          pathColor: '#c0c3c7',
          trailColor: '#d6d6d6',
          textColor: '#333',
          border: "1px solid gray"
        })}
      />

      <div
        style={{
          position:'absolute',
          top: '45%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '24px',
          color: 'blue',
        }}
      >
        <GoArrowDown />
      </div>
    </div>
    );
};

export default ProgressBar;