import React, { useState } from "react";
// import { FaArrowCircleRight } from "react-icons/fa";

const ImageSlider = ({ colorImages }) => {
    const [currentIndexByColor, setCurrentIndexByColor] = useState({
        gray: 0,
        black: 0,
    });
    const [selectedColor, setSelectedColor] = useState("gray");
    const [thumbnailIndex, setThumbnailIndex] = useState(0);

    const thumbnailsToShow = 4;

    const handleColorClick = (color) => {
        setSelectedColor(color);
        setThumbnailIndex(0);
    };

    const handleThumbnailClick = (index) => {
        setCurrentIndexByColor((prevState) => ({
            ...prevState,
            [selectedColor]: index,
        }));
    };

    const getCurrentImages = () => {
        return colorImages[selectedColor];
    };

    const handleNextThumbnailSet = () => {
        setThumbnailIndex((prev) =>
            Math.min(prev + thumbnailsToShow, getCurrentImages().length - thumbnailsToShow)
        );
    };

    const handlePrevThumbnailSet = () => {
        setThumbnailIndex((prev) => Math.max(prev - thumbnailsToShow, 0));
    };

    const handleNext = () => {
        setCurrentIndexByColor((prevState) => ({
            ...prevState,
            [selectedColor]: (prevState[selectedColor] + 1) % getCurrentImages().length,
        }));
    };

    const handlePrev = () => {
        setCurrentIndexByColor((prevState) => ({
            ...prevState,
            [selectedColor]: (prevState[selectedColor] - 1 + getCurrentImages().length) % getCurrentImages().length,
        }));
    };

    return (
        <div className="slider-container container">
      <div className="main-image-container">
        <img
          src={getCurrentImages()[currentIndexByColor[selectedColor]]}
          alt={`Main ${currentIndexByColor[selectedColor]}`}
          className="main-image"
        />
        <div className="color-container">
          {Object.keys(colorImages).map((color) => (
            <div
              key={color}
              className={`color-${color} colorBox ${
                selectedColor === color ? "selected" : ""
              }`}
              onClick={() => handleColorClick(color)}
            ></div>
          ))}
        </div>
      </div>

      <div className="thumbnail-slider-wrapper">
        <button
          className="next-thumb-button1"
          onClick={handlePrevThumbnailSet}
          disabled={thumbnailIndex === 0}
        >
          &#10094;
        </button>

        <div className="thumbnail-container">
          {getCurrentImages()
            .slice(thumbnailIndex, thumbnailIndex + thumbnailsToShow)
            .map((image, index) => (
              <div className="thumbNailBox" key={index}>
                <img
                  src={image}
                  alt={`Thumbnail ${thumbnailIndex + index}`}
                  className={`thumbnail ${
                    thumbnailIndex + index === currentIndexByColor[selectedColor] ? "active" : ""
                  }`}
                  onClick={() => handleThumbnailClick(thumbnailIndex + index)}
                />
              </div>
            ))}
        </div>

        <button
            className="next-thumb-button1"
          onClick={handleNextThumbnailSet}
          disabled={thumbnailIndex + thumbnailsToShow >= getCurrentImages().length}
        >
          &#10095;
        </button>
      </div>
    </div>
    );
};

export default ImageSlider;