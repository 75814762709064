import React from "react";
import { midImag } from "..";

function SlideTwo() {
    return (
        <div className="parent">
    <div className="div1 box">
      <div className="contentWrap">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clipPath="url(#clip0_250_2828)">
              <path
                d="M10 20C4.48333 20 0 15.5167 0 10C0 4.48333 4.48333 0 10 0C15.5167 0 20 4.48333 20 10C20 15.5167 15.5167 20 10 20ZM10 1.66667C5.40833 1.66667 1.66667 5.40833 1.66667 10C1.66667 14.5917 5.40833 18.3333 10 18.3333C14.5917 18.3333 18.3333 14.5917 18.3333 10C18.3333 5.40833 14.5917 1.66667 10 1.66667ZM10 15.8333C9.85833 15.8333 9.71667 15.8 9.58333 15.725C9.18333 15.5 9.04167 14.9833 9.275 14.5917L11.425 10.8417H8.01667C7.59167 10.8417 7.19167 10.6417 6.93333 10.3C6.675 9.95833 6.6 9.51667 6.725 9.10833C6.74167 9.05 9.275 4.6 9.275 4.6C9.5 4.2 10.0083 4.05833 10.4083 4.29167C10.8083 4.51667 10.95 5.025 10.7167 5.425L8.575 9.175H11.9917C12.4833 9.175 12.9333 9.44167 13.1667 9.88333C13.4 10.325 13.375 10.8417 13.1 11.25L10.7167 15.425C10.5667 15.6917 10.2833 15.8417 9.99167 15.8417L10 15.8333ZM11.6917 10.3667C11.6917 10.3667 11.6917 10.375 11.6833 10.3833V10.3667H11.6917Z"
                fill="#2D4CC3"
              />
            </g>
            <defs>
              <clipPath id="clip0_250_2828">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <h6>Durability & reliability</h6>
        <p>
          Set your imagination free with the powerful Solaris book 14X OLED!
          Whether it's for creative work or gaming fun
        </p>
      </div>
    </div>
    <div className="div2 box">
      <div className="contentWrap">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clipPath="url(#clip0_250_2835)">
              <path
                d="M19.235 12.8341C18.94 13.5058 18.5642 14.1433 18.1158 14.7283C17.7183 15.2475 17.5 15.9433 17.5 16.69V17.4991C17.5 18.8775 16.3783 19.9991 15 19.9991H9.16667C7.78833 19.9991 6.66667 18.8775 6.66667 17.4991H6.255C4.1725 17.4991 2.39667 15.9425 2.12417 13.8783L1.94167 12.4991H1.66667C0.584167 12.4991 0 11.6408 0 10.8325C0 9.64912 2.44333 4.98662 3.51917 3.65662C5.04083 1.62412 7.38 0.298287 9.9175 0.0457867C10.3783 0.00328672 10.7833 0.33412 10.8292 0.792453C10.875 1.24995 10.5408 1.65912 10.0825 1.70412C8.00917 1.91079 6.09583 2.99579 4.83333 4.68162C3.715 6.06579 1.735 10.1608 1.66583 10.8541L2.6725 10.8341C3.09 10.8341 3.44417 11.1441 3.49833 11.5583L3.77667 13.6616C3.94 14.9 5.00583 15.8341 6.255 15.8341H7.5C7.96 15.8341 8.33333 16.2075 8.33333 16.6675V17.5008C8.33333 17.96 8.7075 18.3341 9.16667 18.3341H15C15.46 18.3341 15.8333 17.96 15.8333 17.5008V16.6916C15.8333 15.5808 16.1742 14.5241 16.7925 13.7166C17.1592 13.2375 17.4675 12.7158 17.7083 12.1666C17.8925 11.7458 18.3825 11.5516 18.8058 11.7375C19.2275 11.9225 19.4192 12.4141 19.235 12.835V12.8341ZM11.7792 3.18829C12.1283 1.67079 13.3342 0.463287 14.8525 0.113287C16.1233 -0.17838 17.425 0.10912 18.4258 0.90412C19.4267 1.69995 20.0008 2.88912 20.0008 4.16662C20.0008 5.55162 19.3117 6.83995 18.1567 7.61412C17.7458 7.88995 17.5 8.38745 17.5 8.94662V9.58245C17.5 10.2733 16.94 10.8341 16.2508 10.8341H15.4175C14.7283 10.8341 14.1675 10.2733 14.1675 9.58412V8.94079C14.1675 8.39329 13.9175 7.88662 13.5158 7.61829C12.0658 6.64829 11.3842 4.90995 11.78 3.18995L11.7792 3.18829ZM14.4408 6.23079C14.91 6.54412 15.265 6.98829 15.5042 7.49912H16.1633C16.4033 6.98745 16.7583 6.54329 17.2275 6.22912C17.9192 5.76495 18.3325 4.99412 18.3325 4.16579C18.3325 3.39912 17.9883 2.68579 17.3867 2.20745C16.9375 1.84995 16.4017 1.66495 15.8383 1.66495C15.6367 1.66495 15.4325 1.68829 15.2258 1.73579C14.34 1.94079 13.6075 2.67412 13.4025 3.56079C13.16 4.61662 13.5575 5.63912 14.44 6.22995L14.4408 6.23079Z"
                fill="#2D4CC3"
              />
            </g>
            <defs>
              <clipPath id="clip0_250_2835">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <h6>Innovation</h6>
        <p>
          Set your imagination free with the powerful Solaris book 14X OLED!
          Whether it’s for creative work or gaming fun
        </p>
      </div>
    </div>
    <div className="div3 box">
      <div className="contentWrap">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clipPath="url(#clip0_250_2830)">
              <path
                d="M12.3592 14.1667C12.1458 14.1667 11.9325 14.085 11.77 13.9225C11.4442 13.5967 11.4442 13.07 11.77 12.7442L13.9225 10.5917C14.0792 10.4342 14.1667 10.225 14.1667 10.0017C14.1667 9.77833 14.08 9.57 13.9225 9.4125L11.77 7.25917C11.4442 6.93333 11.4442 6.40583 11.77 6.08083C12.0958 5.755 12.6225 5.755 12.9483 6.08083L15.1008 8.23333C15.5725 8.705 15.8333 9.33333 15.8333 10.0008C15.8333 10.6683 15.5733 11.2967 15.1008 11.7692L12.9483 13.9217C12.7858 14.0842 12.5725 14.1667 12.3592 14.1667ZM8.23 13.9183C8.55583 13.5925 8.55583 13.0658 8.23 12.74L6.0775 10.5875C5.92 10.43 5.83333 10.2208 5.83333 9.9975C5.83333 9.77417 5.92 9.56583 6.0775 9.40833L8.23 7.25583C8.55583 6.93 8.55583 6.40333 8.23 6.0775C7.90417 5.75167 7.3775 5.75167 7.05167 6.0775L4.89917 8.23C4.42667 8.7025 4.16667 9.33 4.16667 9.99833C4.16667 10.6667 4.42667 11.2942 4.89917 11.7667L7.05167 13.9192C7.21417 14.0817 7.4275 14.1633 7.64083 14.1633C7.85417 14.1633 8.0675 14.0808 8.23 13.9183ZM20 15.8333V4.16667C20 1.86917 18.1308 0 15.8333 0H4.16667C1.86917 0 0 1.86917 0 4.16667V15.8333C0 18.1308 1.86917 20 4.16667 20H15.8333C18.1308 20 20 18.1308 20 15.8333ZM15.8333 1.66667C17.2117 1.66667 18.3333 2.78833 18.3333 4.16667V15.8333C18.3333 17.2117 17.2117 18.3333 15.8333 18.3333H4.16667C2.78833 18.3333 1.66667 17.2117 1.66667 15.8333V4.16667C1.66667 2.78833 2.78833 1.66667 4.16667 1.66667H15.8333Z"
                fill="#2D4CC3"
              />
            </g>
            <defs>
              <clipPath id="clip0_250_2830">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <h6>IT Managment</h6>
        <p>
          Set your imagination free with the powerful Solaris book 14X OLED!
          Whether it's for creative work or gaming fun
        </p>
      </div>
    </div>
    <div className="div4 box">
      <div className="contentWrap">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clipPath="url(#clip0_250_2837)">
              <path
                d="M1.66667 5C1.66667 4.54 2.04 4.16667 2.5 4.16667H3.33333V3.33333C3.33333 2.87333 3.70667 2.5 4.16667 2.5C4.62667 2.5 5 2.87333 5 3.33333V4.16667H5.83333C6.29333 4.16667 6.66667 4.54 6.66667 5C6.66667 5.46 6.29333 5.83333 5.83333 5.83333H5V6.66667C5 7.12667 4.62667 7.5 4.16667 7.5C3.70667 7.5 3.33333 7.12667 3.33333 6.66667V5.83333H2.5C2.04 5.83333 1.66667 5.46 1.66667 5ZM9.16667 3.33333H10V4.16667C10 4.62667 10.3733 5 10.8333 5C11.2933 5 11.6667 4.62667 11.6667 4.16667V3.33333H12.5C12.96 3.33333 13.3333 2.96 13.3333 2.5C13.3333 2.04 12.96 1.66667 12.5 1.66667H11.6667V0.833333C11.6667 0.373333 11.2933 0 10.8333 0C10.3733 0 10 0.373333 10 0.833333V1.66667H9.16667C8.70667 1.66667 8.33333 2.04 8.33333 2.5C8.33333 2.96 8.70667 3.33333 9.16667 3.33333ZM19.3633 10.8458L13.6925 17.2108C12.1125 18.9842 9.84417 20.0008 7.47083 20.0008H3.33333C1.495 20.0008 0 18.5058 0 16.6675V12.5008C0 10.6625 1.495 9.1675 3.33333 9.1675H10.715C11.6642 9.1675 12.4967 9.675 12.9558 10.4333L15.6358 7.48833C16.0875 6.9925 16.7042 6.70167 17.3742 6.67083C18.0475 6.63583 18.6858 6.87083 19.1808 7.3225C20.1925 8.245 20.2742 9.825 19.3633 10.8458ZM18.0583 8.55333C17.8925 8.40167 17.675 8.325 17.4517 8.335C17.2267 8.34583 17.02 8.4425 16.8683 8.60917L13.18 12.6625C12.8592 13.5542 12.0642 14.2383 11.0842 14.3783L6.78417 14.9925C6.32917 15.0583 5.90667 14.7417 5.84167 14.285C5.77667 13.8292 6.09333 13.4067 6.54833 13.3417L10.8492 12.7275C11.3158 12.6617 11.6667 12.2567 11.6667 11.7858C11.6667 11.2608 11.24 10.8333 10.715 10.8333H3.33333C2.41417 10.8333 1.66667 11.5808 1.66667 12.5V16.6667C1.66667 17.5858 2.41417 18.3333 3.33333 18.3333H7.47083C9.37 18.3333 11.1842 17.5192 12.4483 16.1017L18.1192 9.73667C18.425 9.39333 18.3975 8.8625 18.0583 8.5525V8.55333Z"
                fill="#2D4CC3"
              />
            </g>
            <defs>
              <clipPath id="clip0_250_2837">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <h6>Premium Support & Service</h6>
        <p>
          Set your imagination free with the powerful Solaris book 14X OLED!
          Whether it's for creative work or gaming fun
        </p>
      </div>
    </div>
    <div className="div5 box">
      <svg
        className="spinner"
        xmlns="http://www.w3.org/2000/svg"
        width="455"
        height="455"
        viewBox="0 0 466 466"
        fill="none"
      >
        <path
          d="M3.14048 217.042C1.69505 216.948 0.616894 215.68 0.735372 214.223C0.842003 212.754 2.09787 211.675 3.54331 211.818C4.9769 211.948 6.0669 213.216 5.94842 214.637C5.82994 216.059 4.57407 217.137 3.14048 217.042Z"
          fill="#2D4CC3"
        />
        <path
          d="M5.4627 196.214C4.04096 195.989 3.08128 194.638 3.34194 193.193C3.60259 191.747 4.96509 190.787 6.38683 191.048C7.80857 191.309 8.7564 192.659 8.5076 194.069C8.24695 195.479 6.88444 196.439 5.4627 196.214Z"
          fill="#2D4CC3"
        />
        <path
          d="M9.69229 175.67C8.27055 175.314 7.45305 173.881 7.85588 172.471C8.2587 171.061 9.70414 170.231 11.1022 170.622C12.4884 171.013 13.3059 172.447 12.9149 173.821C12.5121 175.184 11.0666 176.025 9.69229 175.67Z"
          fill="#2D4CC3"
        />
        <path
          d="M15.8294 155.623C14.4788 155.149 13.7205 153.621 14.23 152.247C14.7631 150.884 16.3034 150.197 17.6422 150.706C18.9928 151.228 19.6563 152.72 19.135 154.047C18.6374 155.386 17.1919 156.109 15.8294 155.623Z"
          fill="#2D4CC3"
        />
        <path
          d="M23.7558 136.216C22.4406 135.612 21.8719 134.036 22.4762 132.697C23.0567 131.347 24.7154 130.837 25.9832 131.465C27.2983 132.117 27.7603 133.633 27.1916 134.948C26.6111 136.252 25.0709 136.82 23.7558 136.216Z"
          fill="#2D4CC3"
        />
        <path
          d="M33.4235 117.603C32.1676 116.88 31.753 115.269 32.4757 113.989C33.2103 112.722 34.7979 112.295 36.0419 113.065C37.2622 113.812 37.6887 115.423 36.9779 116.667C36.267 117.911 34.6794 118.326 33.4235 117.603Z"
          fill="#2D4CC3"
        />
        <path
          d="M44.7382 99.9616C43.5534 99.1322 43.2927 97.4854 44.1339 96.2887C44.987 95.0921 46.6101 94.8196 47.7712 95.6845C48.9323 96.5494 49.193 98.1962 48.3755 99.3573C47.5461 100.518 45.923 100.791 44.7382 99.9616Z"
          fill="#2D4CC3"
        />
        <path
          d="M57.6051 83.3981C56.5032 82.4621 56.3847 80.8034 57.3563 79.7015C58.3159 78.5878 59.9628 78.4812 61.041 79.4409C62.1191 80.4006 62.2257 82.0593 61.2898 83.1374C60.3538 84.2156 58.7069 84.3341 57.6051 83.3981Z"
          fill="#2D4CC3"
        />
        <path
          d="M71.9054 68.0788C70.8984 67.0481 70.9339 65.3893 72.0002 64.3823C73.0665 63.3752 74.7252 63.4107 75.7086 64.4652C76.692 65.5197 76.6564 67.1784 75.6138 68.1618C74.583 69.1451 72.9244 69.1096 71.9054 68.0788Z"
          fill="#2D4CC3"
        />
        <path
          d="M87.5327 54.0983C86.6204 52.9846 86.8099 51.3378 87.9592 50.4255C89.1084 49.5132 90.7671 49.7146 91.6557 50.852C92.5443 51.9894 92.3429 53.6363 91.2292 54.5249C90.1036 55.4135 88.4568 55.212 87.5327 54.0983Z"
          fill="#2D4CC3"
        />
        <path
          d="M104.368 41.5869C103.563 40.3903 103.906 38.779 105.139 37.9852C106.371 37.1914 108.006 37.535 108.788 38.7553C109.57 39.9756 109.214 41.5869 108.018 42.357C106.809 43.1272 105.174 42.7836 104.368 41.5869Z"
          fill="#2D4CC3"
        />
        <path
          d="M122.282 30.6867C121.583 29.419 122.045 27.7959 123.361 27.1442C124.664 26.4807 126.263 26.9783 126.927 28.2579C127.59 29.5375 127.093 31.1133 125.825 31.7649C124.545 32.3928 122.97 31.9426 122.282 30.6867Z"
          fill="#2D4CC3"
        />
        <path
          d="M141.12 21.4692C140.54 20.1541 141.156 18.5665 142.495 17.986C143.857 17.4528 145.409 18.0926 145.954 19.4314C146.499 20.7702 145.86 22.2749 144.533 22.8081C143.217 23.3768 141.701 22.8081 141.12 21.4692Z"
          fill="#2D4CC3"
        />
        <path
          d="M160.717 14.017C160.267 12.6426 161.025 11.1498 162.411 10.6877C163.797 10.1901 165.29 11.0313 165.705 12.4057C166.131 13.8037 165.338 15.1899 163.987 15.6757C162.636 16.1259 161.167 15.3913 160.717 14.017Z"
          fill="#2D4CC3"
        />
        <path
          d="M180.906 8.37742C180.574 6.96752 181.474 5.55762 182.896 5.22588C184.33 4.89414 185.716 5.77089 186.012 7.19263C186.296 8.60253 185.408 10.0124 184.022 10.3323C182.624 10.6641 181.237 9.78732 180.906 8.37742Z"
          fill="#2D4CC3"
        />
        <path
          d="M201.521 4.59794C201.319 3.16434 202.338 1.86107 203.796 1.6715C205.253 1.48194 206.556 2.50086 206.722 3.93445C206.876 5.36805 205.857 6.68317 204.447 6.86088C203.037 7.05045 201.722 6.03153 201.521 4.59794Z"
          fill="#2D4CC3"
        />
        <path
          d="M222.409 2.67843C222.338 1.23299 223.475 0.0363545 224.944 0.000810846C226.413 -0.0347329 227.622 1.10267 227.657 2.54811C227.681 3.99355 226.555 5.19019 225.122 5.22574C223.688 5.26128 222.468 4.12388 222.409 2.67843Z"
          fill="#2D4CC3"
        />
        <path
          d="M243.379 2.67854C243.438 1.24495 244.682 0.0601574 246.14 0.190484C247.609 0.297115 248.711 1.56484 248.604 2.99844C248.509 4.44388 247.265 5.52204 245.843 5.41541C244.422 5.28508 243.308 4.13583 243.379 2.67854Z"
          fill="#2D4CC3"
        />
        <path
          d="M264.255 4.59785C264.445 3.16425 265.783 2.15718 267.241 2.3349C268.698 2.48892 269.681 3.92252 269.444 5.33242C269.207 6.77786 267.892 7.66645 266.471 7.51243C265.061 7.33471 264.065 6.03145 264.255 4.59785Z"
          fill="#2D4CC3"
        />
        <path
          d="M284.87 8.37723C285.19 6.96733 286.612 6.09059 288.045 6.42233C289.479 6.75407 290.344 8.15212 289.989 9.55017C289.633 10.9482 288.211 11.825 286.813 11.5051C285.415 11.197 284.55 9.78713 284.87 8.37723Z"
          fill="#2D4CC3"
        />
        <path
          d="M305.071 14.017C305.521 12.6427 307.014 11.9081 308.4 12.382C309.786 12.856 310.521 14.3369 310.035 15.6995C309.549 17.062 308.056 17.7965 306.706 17.3345C305.355 16.8724 304.621 15.3914 305.071 14.017Z"
          fill="#2D4CC3"
        />
        <path
          d="M324.667 21.4692C325.236 20.1423 326.788 19.5617 328.127 20.166C329.466 20.7702 330.058 22.3223 329.442 23.6374C328.838 24.9525 327.286 25.5331 325.982 24.9407C324.679 24.3483 324.087 22.7962 324.667 21.4692Z"
          fill="#2D4CC3"
        />
        <path
          d="M343.505 30.6869C344.193 29.4192 345.792 28.9808 347.06 29.7154C348.328 30.45 348.778 32.0494 348.043 33.3053C347.32 34.5493 345.721 34.9877 344.489 34.2768C343.245 33.566 342.806 31.9546 343.505 30.6869Z"
          fill="#2D4CC3"
        />
        <path
          d="M361.419 41.587C362.237 40.3666 363.86 40.0941 365.056 40.9353C366.253 41.7884 366.549 43.4352 365.708 44.6082C364.867 45.7811 363.244 46.0655 362.083 45.2361C360.91 44.4186 360.613 42.7718 361.419 41.587Z"
          fill="#2D4CC3"
        />
        <path
          d="M378.243 54.0984C379.143 52.9847 380.849 52.7715 381.939 53.7549C383.053 54.7145 383.184 56.3851 382.248 57.4869C381.312 58.5888 379.665 58.7191 378.587 57.7831C377.532 56.8116 377.319 55.2358 378.243 54.0984Z"
          fill="#2D4CC3"
        />
        <path
          d="M393.871 68.0788C394.878 67.048 396.56 67.0243 397.614 68.0433C398.621 69.1096 398.61 70.7801 397.579 71.7872C396.548 72.7943 394.901 72.7706 393.918 71.7398C392.887 70.7446 392.863 69.1096 393.871 68.0788Z"
          fill="#2D4CC3"
        />
        <path
          d="M408.183 83.3982C409.285 82.4622 410.943 82.5925 411.915 83.7062C412.898 84.7962 412.673 86.5142 411.571 87.4146C410.434 88.3387 408.858 88.1255 407.898 87.0592C406.951 85.981 407.081 84.346 408.183 83.3982Z"
          fill="#2D4CC3"
        />
        <path
          d="M421.038 99.9616C422.211 99.1204 423.857 99.4166 424.711 100.613C425.564 101.81 425.279 103.433 424.059 104.251C422.874 105.044 421.227 104.76 420.398 103.587C419.58 102.414 419.865 100.791 421.038 99.9616Z"
          fill="#2D4CC3"
        />
        <path
          d="M432.352 117.603C433.596 116.881 435.208 117.319 435.942 118.587C436.677 119.854 436.238 121.454 434.971 122.141C433.703 122.828 432.092 122.39 431.381 121.158C430.67 119.926 431.108 118.338 432.352 117.603Z"
          fill="#2D4CC3"
        />
        <path
          d="M442.02 136.216C443.335 135.612 444.887 136.192 445.491 137.531C446.096 138.87 445.515 140.422 444.188 140.991C442.861 141.56 441.309 140.979 440.717 139.676C440.124 138.372 440.705 136.82 442.02 136.216Z"
          fill="#2D4CC3"
        />
        <path
          d="M449.958 155.623C451.321 155.137 452.802 155.872 453.275 157.258C453.749 158.644 453.015 160.137 451.64 160.587C450.266 161.038 448.785 160.303 448.323 158.952C447.861 157.602 448.596 156.109 449.958 155.623Z"
          fill="#2D4CC3"
        />
        <path
          d="M456.095 175.67C457.493 175.314 458.892 176.179 459.223 177.613C459.555 179.046 458.678 180.468 457.268 180.788C455.858 181.108 454.46 180.243 454.141 178.845C453.821 177.447 454.697 176.025 456.095 175.67Z"
          fill="#2D4CC3"
        />
        <path
          d="M460.313 196.214C461.723 195.989 463.145 196.96 463.311 198.418C463.5 199.875 462.481 201.214 461.048 201.403C459.614 201.593 458.311 200.598 458.133 199.188C457.979 197.766 458.88 196.439 460.313 196.214Z"
          fill="#2D4CC3"
        />
        <path
          d="M462.647 217.043C464.093 216.948 465.349 218.05 465.455 219.507C465.597 220.964 464.401 222.208 462.967 222.268C461.51 222.327 460.361 221.225 460.23 219.792C460.124 218.382 461.202 217.149 462.647 217.043Z"
          fill="#2D4CC3"
        />
        <path
          d="M463.109 238.002C464.555 238.025 465.692 239.246 465.657 240.715C465.621 242.184 464.424 243.321 462.979 243.25C461.533 243.179 460.396 241.971 460.432 240.537C460.455 239.115 461.664 237.978 463.109 238.002Z"
          fill="#2D4CC3"
        />
        <path
          d="M461.711 258.925C463.145 259.079 464.164 260.394 463.974 261.851C463.784 263.309 462.481 264.327 461.048 264.126C459.614 263.925 458.607 262.621 458.785 261.2C458.974 259.79 460.277 258.771 461.711 258.925Z"
          fill="#2D4CC3"
        />
        <path
          d="M458.453 279.635C459.863 279.919 460.752 281.317 460.42 282.751C460.088 284.185 458.678 285.073 457.28 284.741C455.87 284.41 454.994 283.023 455.313 281.625C455.633 280.239 457.031 279.351 458.453 279.635Z"
          fill="#2D4CC3"
        />
        <path
          d="M453.252 299.954C454.626 300.369 455.468 301.862 454.97 303.248C454.496 304.634 453.003 305.392 451.641 304.942C450.266 304.492 449.532 303.023 449.982 301.672C450.468 300.322 451.854 299.528 453.252 299.954Z"
          fill="#2D4CC3"
        />
        <path
          d="M446.203 319.705C447.541 320.25 448.193 321.79 447.648 323.164C447.067 324.515 445.492 325.119 444.165 324.539C442.838 323.958 442.257 322.441 442.826 321.126C443.359 319.799 444.864 319.16 446.203 319.705Z"
          fill="#2D4CC3"
        />
        <path
          d="M437.399 338.732C438.679 339.396 439.176 340.995 438.513 342.299C437.861 343.614 436.238 344.076 434.971 343.377C433.715 342.678 433.264 341.114 433.892 339.834C434.544 338.555 436.12 338.069 437.399 338.732Z"
          fill="#2D4CC3"
        />
        <path
          d="M426.902 356.871C428.123 357.653 428.466 359.277 427.672 360.521C426.879 361.753 425.267 362.096 424.071 361.291C422.874 360.485 422.53 358.85 423.3 357.642C424.071 356.445 425.682 356.101 426.902 356.871Z"
          fill="#2D4CC3"
        />
        <path
          d="M414.794 373.992C415.931 374.88 416.133 376.527 415.22 377.688C414.308 378.837 412.673 379.039 411.547 378.127C410.434 377.214 410.232 375.556 411.121 374.43C412.009 373.304 413.656 373.115 414.794 373.992Z"
          fill="#2D4CC3"
        />
        <path
          d="M401.192 389.951C402.247 390.934 402.282 392.593 401.275 393.659C400.268 394.725 398.61 394.761 397.579 393.754C396.548 392.747 396.513 391.088 397.496 390.046C398.479 389.003 400.138 388.967 401.192 389.951Z"
          fill="#2D4CC3"
        />
        <path
          d="M386.217 404.618C387.176 405.697 387.07 407.344 385.956 408.303C384.842 409.263 383.184 409.144 382.26 408.054C381.324 406.953 381.442 405.306 382.52 404.37C383.598 403.434 385.245 403.54 386.217 404.618Z"
          fill="#2D4CC3"
        />
        <path
          d="M369.973 417.877C370.838 419.038 370.566 420.661 369.369 421.514C368.173 422.367 366.526 422.094 365.696 420.91C364.867 419.725 365.139 418.102 366.301 417.272C367.462 416.455 369.108 416.715 369.973 417.877Z"
          fill="#2D4CC3"
        />
        <path
          d="M352.581 429.606C353.351 430.85 352.924 432.437 351.657 433.172C350.389 433.906 348.766 433.48 348.043 432.224C347.32 430.968 347.747 429.381 348.979 428.67C350.223 427.959 351.834 428.385 352.581 429.606Z"
          fill="#2D4CC3"
        />
        <path
          d="M334.181 439.665C334.809 440.944 334.3 442.603 332.949 443.172C331.61 443.776 330.034 443.207 329.43 441.892C328.826 440.577 329.395 439.037 330.698 438.444C332.025 437.888 333.541 438.35 334.181 439.665Z"
          fill="#2D4CC3"
        />
        <path
          d="M314.952 448.006C315.473 449.357 314.786 450.885 313.412 451.418C312.037 451.927 310.509 451.181 310.035 449.819C309.549 448.444 310.272 446.999 311.611 446.501C312.938 445.992 314.431 446.655 314.952 448.006Z"
          fill="#2D4CC3"
        />
        <path
          d="M295.036 454.557C295.427 455.944 294.597 457.401 293.187 457.804C291.778 458.207 290.344 457.389 289.989 455.967C289.633 454.581 290.474 453.148 291.837 452.745C293.211 452.354 294.645 453.171 295.036 454.557Z"
          fill="#2D4CC3"
        />
        <path
          d="M274.61 459.273C274.871 460.695 273.911 462.057 272.466 462.318C271.02 462.579 269.67 461.631 269.444 460.197C269.219 458.763 270.179 457.413 271.589 457.152C272.987 456.903 274.349 457.851 274.61 459.273Z"
          fill="#2D4CC3"
        />
        <path
          d="M253.841 462.116C253.971 463.55 252.893 464.818 251.436 464.924C249.967 465.031 248.711 463.953 248.616 462.519C248.521 461.074 249.599 459.818 251.021 459.711C252.443 459.605 253.699 460.683 253.841 462.116Z"
          fill="#2D4CC3"
        />
        <path
          d="M232.894 463.112C232.894 464.557 231.709 465.695 230.24 465.659C228.771 465.623 227.61 464.427 227.645 462.981C227.681 461.536 228.866 460.399 230.299 460.434C231.733 460.47 232.882 461.666 232.894 463.112Z"
          fill="#2D4CC3"
        />
        <path
          d="M211.947 462.116C211.817 463.55 210.525 464.628 209.068 464.522C207.611 464.332 206.568 463.029 206.734 461.583C206.9 460.15 208.179 459.131 209.601 459.308C211.023 459.427 212.065 460.683 211.947 462.116Z"
          fill="#2D4CC3"
        />
        <path
          d="M191.178 459.273C190.917 460.695 189.543 461.643 188.097 461.382C186.652 461.133 185.728 459.759 186.024 458.325C186.32 456.927 187.694 455.991 189.104 456.24C190.502 456.489 191.426 457.851 191.178 459.273Z"
          fill="#2D4CC3"
        />
        <path
          d="M170.74 454.558C170.349 455.956 168.892 456.749 167.482 456.358C166.072 455.956 165.266 454.51 165.693 453.136C166.119 451.75 167.577 450.956 168.951 451.335C170.325 451.726 171.131 453.171 170.74 454.558Z"
          fill="#2D4CC3"
        />
        <path
          d="M150.836 448.006C150.326 449.356 148.798 450.02 147.435 449.475C146.073 448.942 145.409 447.413 145.954 446.074C146.499 444.736 148.028 444.084 149.355 444.605C150.682 445.138 151.345 446.655 150.836 448.006Z"
          fill="#2D4CC3"
        />
        <path
          d="M131.595 439.665C130.967 440.968 129.391 441.477 128.088 440.802C126.784 440.139 126.263 438.551 126.938 437.271C127.602 435.992 129.178 435.482 130.445 436.134C131.713 436.786 132.223 438.361 131.595 439.665Z"
          fill="#2D4CC3"
        />
        <path
          d="M113.195 429.606C112.449 430.838 110.837 431.205 109.605 430.411C108.373 429.617 108.006 427.994 108.788 426.774C109.57 425.554 111.181 425.198 112.378 425.968C113.574 426.738 113.941 428.373 113.195 429.606Z"
          fill="#2D4CC3"
        />
        <path
          d="M95.8143 417.876C94.9494 419.049 93.2907 419.298 92.1414 418.374C90.9922 417.462 90.7671 415.815 91.6675 414.666C92.568 413.516 94.1911 413.315 95.3167 414.204C96.4185 415.104 96.6673 416.715 95.8143 417.876Z"
          fill="#2D4CC3"
        />
        <path
          d="M79.571 404.618C78.6232 405.685 76.9171 405.815 75.839 404.808C74.7727 403.801 74.7134 402.119 75.7086 401.064C76.692 400.01 78.3389 399.95 79.3696 400.946C80.4122 401.929 80.5307 403.528 79.571 404.618Z"
          fill="#2D4CC3"
        />
        <path
          d="M64.5833 389.951C63.5289 390.934 61.8583 390.875 60.8394 389.82C59.8442 388.742 59.9627 387.048 61.029 386.088C62.119 385.117 63.7185 385.247 64.7018 386.29C65.697 387.32 65.6378 388.967 64.5833 389.951Z"
          fill="#2D4CC3"
        />
        <path
          d="M50.9821 373.992C49.8447 374.88 48.186 374.667 47.2737 373.518C46.3496 372.38 46.5984 370.71 47.7713 369.845C48.9324 368.992 50.5437 369.241 51.4441 370.354C52.3327 371.468 52.1195 373.103 50.9821 373.992Z"
          fill="#2D4CC3"
        />
        <path
          d="M38.8854 356.871C37.6651 357.653 36.0419 357.286 35.2481 356.054C34.4543 354.822 34.8216 353.21 36.0537 352.464C37.2859 351.717 38.9209 352.085 39.691 353.281C40.4611 354.478 40.0939 356.089 38.8854 356.871Z"
          fill="#2D4CC3"
        />
        <path
          d="M28.3763 338.732C27.0968 339.396 25.5092 338.886 24.8457 337.583C24.1822 336.28 24.6917 334.704 25.9831 334.076C27.2863 333.448 28.8621 333.958 29.5137 335.225C30.1772 336.493 29.6678 338.057 28.3763 338.732Z"
          fill="#2D4CC3"
        />
        <path
          d="M19.5734 319.705C18.2346 320.25 16.7181 319.586 16.1731 318.224C15.6399 316.861 16.2916 315.333 17.6422 314.823C18.9929 314.314 20.5094 314.977 21.0426 316.304C21.5639 317.631 20.9122 319.16 19.5734 319.705Z"
          fill="#2D4CC3"
        />
        <path
          d="M12.5239 299.954C11.1377 300.381 9.69223 299.575 9.30125 298.165C8.89843 296.755 9.70408 295.298 11.1021 294.907C12.5002 294.516 13.9456 295.322 14.3247 296.696C14.7157 298.071 13.9101 299.528 12.5239 299.954Z"
          fill="#2D4CC3"
        />
        <path
          d="M7.32279 279.635C5.8892 279.931 4.5267 279.007 4.26605 277.562C4.0054 276.116 4.95322 274.742 6.37496 274.481C7.79671 274.221 9.14736 275.157 9.40801 276.567C9.65682 277.976 8.72084 279.351 7.32279 279.635Z"
          fill="#2D4CC3"
        />
        <path
          d="M4.06452 258.925C2.63093 259.091 1.31582 258.048 1.12626 256.591C1.00778 255.134 2.08593 253.842 3.53137 253.712C4.9768 253.581 6.22083 254.636 6.33931 256.058C6.51703 257.479 5.49811 258.759 4.06452 258.925Z"
          fill="#2D4CC3"
        />
        <path
          d="M2.67829 238.002C1.23285 238.037 0.036217 236.876 0.000673511 235.407C-0.03487 233.938 1.10252 232.753 2.54796 232.753C3.9934 232.753 5.19003 233.914 5.22557 235.348C5.26112 236.781 4.12373 237.978 2.67829 238.002Z"
          fill="#2D4CC3"
        />
      </svg>
      <div className="midImagWrap">
        <img src={midImag} alt="Middle" className="img-fluid" />
      </div>
    </div>
  </div>
    );
}

export default SlideTwo;