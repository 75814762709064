import  book1  from "../../../images/book/book1.png";
import  book2  from "../../../images/book/book2.png";
import  book3  from "../../../images/book/book3.png";
import  book4  from "../../../images/book/book4.png";
import  book5  from "../../../images/book/book5.png";
import bookicon from  "../../../images/book/bookicon.png";

import  bookbg  from "../../../images/book/bookbg.png";

const cardData = [
  {
    id: 0,
    title: "Bundle 01",
    price:'22249,000',
    saleprice:"11111499.00",
    subTitle: "7 Courses",
    mainTitle: "11111 Digital Marketing Pro",
    description:
      "Whether for career advancement, new hobbies, or curiosity, this collection has they covered.",
    backgroundImg: bookbg,
    image:book1,
    course: "7 Courses",
    bookicon:bookicon,
  },
  {
    id: 1,
    title: "Bundle 02",
    price:'85,000',
    saleprice:"799.00",
    subTitle: "Includes 7 Courses",
    mainTitle: "Tech Security & Development",
    description:
      "Whether for career advancement, new hobbies, or curiosity, this collection has they covered.",
    backgroundImg: bookbg,
    image:book2,
    course: "7 Courses",
    bookicon:bookicon,
  },
  {
    id: 2,
    title: "Bundle 03",
    price:'65,000',
    saleprice:"599.00",
    subTitle: "Includes 7 Courses",
    mainTitle: "Advanced Tech Mastery",
    description:
      "Whether for career advancement, new hobbies, or curiosity, this collection has they covered.",
    backgroundImg: bookbg,
    image:book3,
    course: "7 Courses",
    bookicon:bookicon,
  },
  {
    id: 3,
    title: "Bundle 04",
    price:'45,000',
    saleprice:"499.00",
    subTitle: "Includes 7 Courses",
    mainTitle: "Full-Stack Development Proficiency",
    description:
      "Whether for career advancement, new hobbies, or curiosity, this collection has they covered.",
    backgroundImg: bookbg,
    image:book4,
    course: "7 Courses",
    bookicon:bookicon,
  },
  {
    id: 4,
    title: "Bundle 05",
     price:'49,000',
    saleprice:"499.00",
    subTitle: "Includes 7 Courses",
    mainTitle: "Creative Suite Mastery",
    description:
      "Whether for career advancement, new hobbies, or curiosity, this collection has they covered.",
    backgroundImg: bookbg,
    image:book5,
    course: "7 Courses",
    bookicon:bookicon,
  },
  
];
export default cardData;
