import React, { useEffect, useState } from "react";
import CommonButton from "../../commonButton/CommonButton";
import api from '../../../../src/api/api';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const OrderSummary = ({
    selectedColor,
    selectedColorid,
    selectedBundle,
    discountedPrice,
    bookid,
    currentStep,
    images,
    name,
    onPreOrder,
    handleSubmit,
}) => {
    const [affiliateCode, setAffiliateCode] = useState("");
    const [affiliate, setAffiliate] = useState([]);
    const [finalDiscountedPrice, setFinalDiscountedPrice] = useState("");
    const [isAffiliateVerified, setIsAffiliateVerified] = useState(false);
    const [affiliateId, setAffiliateId] = useState(null);

    // const userId = localStorage.getItem("userid");

    const id = Cookies.get('id');
    const navigate = useNavigate();

    useEffect(() => {
        const storedAffiliateCode = localStorage.getItem("verifiedAffiliateCode");
        if (storedAffiliateCode) {
            api.get(`/affiliate/getAffiliateByCode/${storedAffiliateCode}`)
                .then((res) => {
                    const affiliateData = res.data.data;
                    if (affiliateData) {
                        setFinalDiscountedPrice(
                            discountedPrice - affiliateData.commissionvalue > 0 ?
                            discountedPrice - affiliateData.commissionvalue :
                            0
                        );
                        setIsAffiliateVerified(true);
                        setAffiliateId(affiliateData.id);
                        setAffiliateCode(storedAffiliateCode);
                    }
                })
                .catch((err) => {
                    console.log("Error fetching affiliate data:", err);
                });
        }
    }, [discountedPrice]);

    const handleAffiliateVerification = async () => {
        try {
            const response = await api.get(`/affiliate/getAffiliateByCode/${affiliateCode}`);
            const affiliateData = response.data.data;
            if (affiliateData) {
                const newDiscountedPrice = discountedPrice - affiliateData.commissionvalue;
                setFinalDiscountedPrice(newDiscountedPrice > 0 ? newDiscountedPrice : 0);
                setIsAffiliateVerified(true);
                setAffiliateId(affiliateData.id);

                localStorage.setItem("verifiedAffiliateCode", affiliateCode);


                toast.success("Affiliate code verified successfully!");
            } else {
                toast.error("Invalid affiliate code. Please correct the code and try again.");
                setFinalDiscountedPrice("");
                setIsAffiliateVerified(false);
                setAffiliateId(null);
                localStorage.removeItem("verifiedAffiliateCode");
            }
        } catch (error) {
            console.error("Error verifying affiliate code:", error);
            toast.error("Error verifying affiliate code. Please try again.");
        }
    };

    const handlePayment = async () => {
        let productColorId = selectedColorid || 1;

        if (selectedColor === "gray") {
            productColorId = 1;
        } else if (selectedColor === "black") {
            productColorId = 2;
        }

        try {
            const amountToPay = finalDiscountedPrice ? finalDiscountedPrice : discountedPrice;

            const orderResponse = await api.post(
                `/orders/createOrder`, {
                    user_id: id,
                    book_id: bookid,
                    affiliate_id: affiliateId,
                    products_id: productColorId,
                    amount: amountToPay
                }

            );

            const razorpayOrder = orderResponse.data.data.responseOrder;

            if (!razorpayOrder) {
                throw new Error('razorpayOrder is undefined');
            }

            if (razorpayOrder.amount === undefined) {
                throw new Error('razorpayOrder.amount is undefined');
            }

            const options = {
                key: process.env.RAZORPAY_KEY_ID,
                amount: razorpayOrder.amount,
                currency: "INR",
                name: name,
                description: "Purchase",
                image: images,
                order_id: razorpayOrder.razorpay_order_id,
                method: {
                    upi: true,
                    netbanking: false,
                    card: false,
                    wallet: false,
                    emi: false,
                },
                handler: async function(response) {
                    try {
                        const verificationResponse = await api.post(`/orders/verifyPayment`, {
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_signature: response.razorpay_signature,
                        });

                        if (verificationResponse.data.message === "Payment verified and order updated successfully") {
                            toast.success("Payment Successful");

                            const modalElement = document.getElementById('staticBackdrop');
                            if (modalElement) {
                                const modal = window.bootstrap.Modal.getInstance(modalElement);
                                if (modal) {
                                    modal.hide();
                                } else {
                                    modal.show()
                                }
                            }
                            navigate("/thank-you");
                        } else {
                            toast.error("Payment verification failed.");
                            navigate("/payment-failed");
                        }
                    } catch (error) {
                        console.error("Error verifying payment:", error);
                        toast.error("Payment verification failed.");
                    }
                },
                prefill: {
                    name: "SolarisBook",
                    email: "solarisbookuser@gmail.com",
                    contact: "9999999999",
                },
                notes: {
                    address: "Gujarat",
                },
                theme: {
                    color: "#3399cc",
                },
            };

            if (window.Razorpay) {
                const razorpay = new window.Razorpay(options);
                razorpay.open();
            } else {
                console.error("Razorpay script not loaded.");
                toast.error("Razorpay script not loaded.");
            }
        } catch (error) {
            console.error("Error during payment:", error);
            toast.error("Something went wrong with the payment.");
        }
    };

    return (
        <div className="order-summary">
      <div className="leftBoxWp">
        <div className="finalProductImgWRap">
          <img src={images} alt="" className="img-fluid" width={114} height={49} />
        </div>
        <div className="nameColrWrap">
          <h2>{name}</h2>
          <p>
            <span>Color:</span> {selectedColor}
          </p>
        </div>
      </div>
      <div className="midBoxWp">
        <h2>Course</h2>
        <p>{selectedBundle}</p>
      </div>

      <div>
        <h2>Discount Code</h2>
        <p>
          <input
            type="text"
            value={affiliateCode}
            onChange={(e) => setAffiliateCode(e.target.value)}
            className="order_affiliate"
          />
          <button
            onClick={handleAffiliateVerification}
            className="verify-button"
            style={{ marginLeft: "10px", padding: "7px 10px", cursor: "pointer", borderRadius: "10px" }}
          >
            Verify
          </button>
        </p>
      </div>
      <div className="rightBoxWp">
        {isAffiliateVerified ? (
          <>
            {finalDiscountedPrice < discountedPrice ? (
              <>
                <h3>
                  <del>₹{discountedPrice}</del>
                </h3>
                <h3 className="pricing">₹{finalDiscountedPrice}</h3>
              </>
            ) : (
              <h3 className="pricing">₹{discountedPrice}</h3>
            )}
          </>
        ) : (
          <h3 className="pricing">₹{discountedPrice}</h3>
        )}

        {currentStep === 2 ? (
          <form onSubmit={handleSubmit(onPreOrder)}>
            <CommonButton buttonText="Checkout Now" type="submit" onClick={handlePayment} />
          </form>
        ) : (
          <CommonButton buttonText="Pre Order Now" onClick={onPreOrder} type="button" />
        )}
      </div>
    </div>
    );
};

export default OrderSummary;