import React from "react";

function Clients() {
    return (
        <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0">
          <div className="client-logo-listing">
            <div className="brands-marquee">
              <div className="js-marquee-wrapper">
                <div
                  className="js-marquee"
                  style={{ marginRight: "0", float: "left" }}
                >
                  <div
                    className="brands-marquee-inner"
                    style={{ marginRight: "0", float: "left" }}
                  >
                    <ul className="m-0">
                      <li>
                        <div className="  logo-brand-black-oracle svg_sprite_bg svg-sprite secondStripe"></div>
                      </li>
                      <li>
                        <div className="  logo-cambridge-v3 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  abdul-latif-jameel-logo svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  abdul-latif-jameel-logo-001 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-mtn svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-sanchobbdo svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-gulfstream svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-daman svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-astrazeneca svg_sprite_bg svg-sprite"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
}

export default Clients;