import pic1 from '../../../images/gray/pic1.png'
import pic2 from '../../../images/gray/pic2.png'
import pic3 from '../../../images/gray/pic3.png'
import pic4 from '../../../images/gray/pic4.png'
import pic5 from '../../../images/gray/pic5.png'
import pic6 from '../../../images/gray/pic6.png'
// black
import img2 from '../../../images/black/2_Blaack.png'
import img1 from '../../../images/black/1_Black.png'
import img3 from '../../../images/black/3_Black.png'
import img5 from '../../../images/black/5_Black.png'
import img8 from '../../../images/black/8_Black.png'
import img9 from '../../../images/black/9_Black.png'
import img11 from '../../../images/black/11_Black.png'
import img12 from '../../../images/black/12_Black.png'


const initialImages = {
    Gray: [
        pic1,
        pic2,
        pic3,
        pic4,
        pic5,
        pic6
    ],
    Black: [
        img8,
        img2,
        img1,
        img3,
        img5,
        img9,
        img11,
        img12
    ],
};

export default initialImages;