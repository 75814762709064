import React from 'react';
import laptop from '../../assets/images/bannerlaptopaffiliate.png';
import CommonButton from '../commonButton/CommonButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProgressBar from './ProgressBar';
import AffiliatePageSection2 from './AffiliatePageSection2';
import AffiliatePageSection3 from './AffiliatePageSection3';
import AffiliatePageSection4 from './AffiliatePageSection4';

const AffiliateProgram = () => {
    return ( <
        >
        <section className="affiliatebg">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-md-6 affiliatesec1main" style={{ marginTop: "100px" }}>
                            <p className="affiliatesec1-para1">
                                Become an <span className="span-1">Affiliate</span> for Solarisbook
                            </p>
                            <p className="affiliatespan2">
                                India's pioneering crafting laptop book-tailored for lifelong learning and suitable for all ages!
                            </p>
                            <div className="banner-affiliate-btn">
                                <CommonButton
                                    buttonText="Join Now"
                                    type="button"
                                    modalId="#staticBackdrop"
                                />
                            </div>
                        </div>
                        <div className="col-md-6 text-center affiliateTv">

                            <img src={laptop} alt="bannerlaptop" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <span className='affiliateprogressmain'>
                <ProgressBar  className='affiliateprogress'/>
            </span>
            </section> <
        AffiliatePageSection2 / >
        <AffiliatePageSection3/> <
        AffiliatePageSection4 / >
        <
        />

    );
};

export default AffiliateProgram;